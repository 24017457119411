.VideoSection {
  max-width: calc(1000rem / 16);
  width: 100%;
  margin: 0 auto;
  header {
    max-width: calc(384rem / 16);
    margin-bottom: clamp(32px, calc(64vw / 7.68), 64px);
  }
  .video {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}
