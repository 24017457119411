.wrapper {
  position: sticky;
  top: clamp(28px, calc(36vw / 7.68), 42px);
  z-index: 9;
  pointer-events: none;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }
  .logo {
    pointer-events: auto;
    display: block;
    position: relative;
    &::before {
      content: '';
      width: 200%;
      height: 200%;
      position: absolute;
      pointer-events: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      mask-image: radial-gradient(rgba(0, 0, 0, 1) 21%, rgba(0, 0, 0, 0) 82%);
      backdrop-filter: blur(13px);
      z-index: -1;
    }
    @media (max-width: 599px) {
      width: 76px;
      .logotype {
        display: none;
      }
    }
  }
  .cta {
    pointer-events: auto;
  }
}
.skipToMainContent {
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 21px;
  top: 21px;
  overflow: hidden;
  padding: 8px 21px;
  background-color: var(--primary-900);
  border: 1px solid var(--secondary-900);
  &:focus-visible {
    opacity: 1;
    pointer-events: auto;
  }
}
