.ChipsAnimation {
  position: absolute;
  z-index: -1;
  top: 0;
  &[data-position-x='left'] {
    left: 0;
    &[data-position-y='top'] {
      transform: translate(-15%, -100%);
    }
    &[data-position-y='center'] {
      top: 50%;
      transform: translate(-15%, -50%);
    }
    &[data-position-y='bottom'] {
      top: 100%;
      transform: translate(-15%, 0%);
    }
    svg {
      width: clamp(182px, calc(323vw / 7.68), 431px);
    }
    margin-left: calc(var(--pageMargin) * -1);
    @media (min-width: 1280px) {
      margin-left: calc((100vw - 1200px) / -2);
    }
  }
  &[data-position-x='right'] {
    right: 0;
    &[data-position-y='top'] {
      transform: translate(15%, -100%);
    }
    &[data-position-y='center'] {
      top: 50%;
      transform: translate(15%, -50%);
    }
    &[data-position-y='bottom'] {
      top: 100%;
      transform: translate(15%, 0%);
    }
    svg {
      width: clamp(198px, calc(314vw / 7.68), 420px);
    }
    margin-right: calc(var(--pageMargin) * -1);
    @media (min-width: 1280px) {
      margin-right: calc((100vw - 1200px) / -2);
    }
  }
  svg {
    height: auto;
    &:last-child {
      position: absolute;
      top: 0;
      g {
        animation: glow 13s infinite;
      }
    }
  }
  @keyframes glow {
    0%,
    25%,
    50%,
    75%,
    100% {
      opacity: 1;
    }
    20%,
    40%,
    60%,
    80% {
      opacity: 0.38;
    }
  }
}
