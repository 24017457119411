.ImageReactor {
  position: relative;
  &[data-highlighted='true'] {
    padding: clamp(24px, calc(48vw / 7.68), 48px);
    border: 1px solid var(--primary-700);
    background: var(--gradient-bg);
  }
  display: grid;
  align-items: center;
  gap: clamp(64px, calc(82vw / 7.68), 82px) 32px;
  @media (min-width: 1000px) {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
  @media (max-width: 999px) {
    place-items: center;
    text-align: center;
    header {
      .ctaBox {
        margin: 0 auto;
      }
    }
  }
  header {
    max-width: calc(451rem / 16);
    .paragraph {
      margin-top: 24px;
      & > *:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    .ctaBox {
      max-width: calc(365rem / 16);
      .cta {
        width: 100%;
        margin-top: 24px;
      }
      .additionalInfo {
        margin-top: 12px;
        font-size: var(--small-font-size);
      }
    }
  }
  .img {
    aspect-ratio: 1/1;
    max-width: 348px;
    max-height: 348px;
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--secondary-500);
    box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.8), 0px 0px 12px 0px rgba(152, 214, 255, 0.6);
    &::before {
      content: '';
      width: 68%;
      height: 68%;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      border-radius: 200px;
      border-radius: 50%;
      background: #181f30;
    }
    .Elipse {
      padding: 16px;
      width: 100%;
      height: 100%;
      animation: Elipse 25s linear infinite;
      @keyframes Elipse {
        100% {
          transform: rotate(360deg);
        }
      }
    }
    img {
      width: 71%;
      height: unset;
      position: absolute;
      bottom: 16%;
      left: 50%;
      transform: translate(-53%, 1%);
    }
  }
}
