.counter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp(4px, calc(8vw / 7.68), 16px);
  max-width: 524px;
  > div {
    display: block;
    text-align: center;
    padding: 16px 8px;
    font-size: clamp(calc(16rem / 16), calc(20vw / 7.68), calc(24rem / 16));
    background-color: var(--primary-700);
    box-shadow: 0px 0px 31.9px 0px rgba(29, 164, 253, 0.1);
    position: relative;
    svg {
      position: absolute;
      height: 100%;
      width: auto;
      top: 0;
      transform: translate3d(0, 0, 0);
      filter: drop-shadow(0px 0px 24px rgba(29, 164, 253, 0.6));
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
  }
}
