.Hero {
  display: grid;
  gap: 32px clamp(48px, calc(64vw / 7.68), 82px);
  @media (min-width: 1050px) {
    grid-template-columns: 1.1fr 1fr;
  }
  header {
    max-width: calc(689rem / 16);
  }
  .decorationText {
    font-size: clamp(calc(96rem / 16), calc(128vw / 7.68), calc(128rem / 16));
    color: var(--primary-700);
    font-family: var(--font-secondary);
    position: relative;
    &::before {
      content: attr(data-text);
      pointer-events: none;
      position: absolute;
      text-shadow: 0px 0px 32px rgba(29, 164, 253, 0.2);
      animation: blink 5s infinite;
      @keyframes blink {
        0%,
        25%,
        50%,
        75%,
        100% {
          opacity: 1;
        }
        20%,
        40%,
        60%,
        80% {
          opacity: 0;
        }
      }
    }
  }
  .paragraph {
    margin: clamp(16px, calc(24vw / 7.68), 28px) 0 clamp(28px, calc(32vw / 7.68), 32px);
  }
  .img {
    max-width: 516px;
    width: 100%;
  }
}
