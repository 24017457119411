.Switch {
  width: 60px;
  height: 34px;
  border-radius: 42px;
  background-color: var(--primary-300);
  padding: 4px;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
  }
  transition: background-color 0.5s var(--easing);
  .dot {
    width: 26px;
    height: 26px;
    background-color: var(--primary-100);
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: transform 0.5s var(--easing), background-color 0.5s var(--easing);
    .tick {
      stroke-dasharray: 12;
      stroke-dashoffset: 12;
      transition: stroke-dashoffset 0.5s var(--easing);
    }
  }
  &:hover,
  &:has(input:focus-visible) {
    background-color: var(--primary-500);
  }
  &:has(input:checked) {
    background-color: var(--primary-100);
    .dot {
      transform: translate(26px);
      background-color: var(--primary-900);
      .tick {
        stroke-dashoffset: 0;
      }
    }
  }
}
